<template>
  <div>
    <b-card style="bg-cover">
      <div class="profile">
        <div class="profile-header">
          <!-- BEGIN profile-header-cover -->
          <div class="profile-header-cover"></div>
          <!-- END profile-header-cover -->
          <!-- BEGIN profile-header-content -->
          <b-jumbotron>
            <b-row>
              <b-col md="auto">
                <div class="profile-header-content">
                  <!-- BEGIN profile-header-img -->
                  <div class="profile-header-img b-avatar">
                    <b-avatar
                      style="width: 100px; height: 100px"
                      :src="userInfo.picture"
                      alt=""
                      class="img-circle avatar"
                    />
                  </div>
                  <!-- END profile-header-img -->
                  <!-- BEGIN profile-header-info -->
                  <div class="profile-header-info">
                    <h4 class="m-t-10 m-b-5 text-dark">
                      {{ userInfo.lastname }} {{ userInfo.firstname }}
                    </h4>
                    <p class="m-b-10 text-dark">{{ fonctionName }}</p>

                    <b-link
                      :to="'/member/edit-profile/' + userInfo.id"
                      class="btn btn-primary"
                    >
                      Editer mon profil
                    </b-link>
                  </div>
                  <!-- END profile-header-info -->
                </div>
              </b-col>
            </b-row>
          </b-jumbotron>
          <!-- END profile-header-content -->
          <!-- BEGIN profile-header-tab -->

          <!-- END profile-header-tab -->
        </div>
        <div class="mt-1">
          <b-tabs>
            <b-tab title="INFORMATIONS PERSONNELLES">
              <hr />
              <div class="p-2">
                <b-row>
                  <b-col>
                    <p><strong>Nom :</strong> {{ userInfo.firstname }}</p>
                    <p><strong>Prénom (s) :</strong> {{ userInfo.lastname }}</p>
                    <p><strong>Adresse :</strong> {{ userInfo.address }}</p>
                  </b-col>
                  <b-col>
                    <p><strong>Téléphone :</strong> {{ userInfo.telephone }}</p>
                    <p>
                      <strong>Situation matrimoniale :</strong>
                      {{ userInfo.marital_status }}
                    </p>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
            <b-tab title="INFORMATIONS DU COMPTE">
              <hr />
              <div class="p-2">
                <b-row>
                  <b-col>
                    <p><strong>Email :</strong> {{ userInfo.email }}</p>
                    <p><strong>Entreprise :</strong> {{ enterpriseName }}</p>
                    <p v-if="userInfo.contract"><strong>Type de contrat :</strong> {{ userInfo.contract.name }}</p>
                  </b-col>
                  <b-col>
                    <p><strong>Titre :</strong> {{ roleName }}</p>
                    <p>
                      <strong>Fonction :</strong> {{ fonctionName }} (
                      {{ serviceName }} )
                    </p>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-card>
    <b-card>
      <div class="alert alert-secondary p-1">
        <h4>MES ENTREPRISES</h4>
        <hr />
        <ul>
          <li>
            {{enterpriseName}}
          </li>
          <!-- <li v-for="(myEnt, index) in myEnterprises" :key="index">
            <span> {{ myEnt.name }} </span>
          </li> -->
        </ul>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      avatar: require("@/assets/images/avatars/13.png"),
      username: "John Doe",
      designation: "UI/UX Designer",
      coverImg: require("@/assets/images/profile/user-uploads/timeline.jpg"),
      myEnterprise: {},
    };
  },

  computed: {
    roleName() {
      return this.userInfo.role?.name;
    },
    fonctionName() {
      return this.userInfo.fonction?.name;
    },
    serviceName() {
      return this.userInfo.service?.name;
    },
    enterpriseName() {
      return this.userInfo.enterprise?.name;
    },
    ...mapState("auth", ["users", "user", "userInfo"]),
    ...mapState("enterprise", ["myEnterprises"]),
  },

  mounted() {
    
    this.getUserEnterprises();
    
  },

  methods: {
    ...mapActions("auth", ["showUser"]),
    ...mapActions("enterprise", ["getUserEnterprises"]),
  },
};
</script>
 
<style>
.cadre {
  margin-left: 2%;
  margin-right: 2%;
}
.but {
  text-align: right;
}
@media only screen and (max-width: 600px) {
  .bouton {
    margin-top: 2%;
  }
  .profil-cadre {
    margin: 0;
    padding: 0;
  }
  .picture {
    margin-bottom: 5%;
  }
  .but {
    text-align: center;
    margin-right: 20%;
  }
}

.profile-header {
  position: relative;
  overflow: hidden;
}

.profile-header .profile-header-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.profile-header .profile-header-cover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.profile-header .profile-header-content {
  color: #fff;
  padding: 25px;
}

.profile-header-img {
  float: left;
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: relative;
  z-index: 10;
  margin: 0 0 -20px;
  padding: 3px;
  border-radius: 4px;
  background: #fff;
}

.profile-header-img img {
  max-width: 100%;
}

.profile-header-info h4 {
  font-weight: 500;
  color: #fff;
}

.profile-header-img + .profile-header-info {
  margin-left: 140px;
}

.profile-header .profile-header-content,
.profile-header .profile-header-tab {
  position: relative;
}

.profile-header .profile-header-tab {
  background: #fff;
  list-style-type: none;
  margin: -10px 0 0;
  padding: 0 0 0 140px;
  white-space: nowrap;
  border-radius: 0;
}

.text-ellipsis,
.text-nowrap {
  white-space: nowrap !important;
}
</style>
